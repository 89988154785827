import React, { useState, useEffect, useCallback } from 'react';
import { Play, Pause, RotateCcw } from 'lucide-react';

const exerciseTime = 45;
const restTime = 20;

const BootcampTimer = () => {
  const [exercises, setExercises] = useState([
    "Mountain Climber (slow-fast)",
    "Stütze halten (Plank hold)",
    "Walking Lunges",
    "Lunge hold + Fersen Lift",
    "Fast Feet + Hold",
    "3x Skaters + Jump Squat",
    "Lunge alternate + Rotation",
    "Overhead Marsch",
    "Bear + Dumbbell switch"
  ]);

  const [currentExercise, setCurrentExercise] = useState(-1);
  const [timeLeft, setTimeLeft] = useState(restTime);
  const [isActive, setIsActive] = useState(false);
  const [isRest, setIsRest] = useState(true);
  const [round, setRound] = useState(1);

  const announceExercise = useCallback(() => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(
        currentExercise === exercises.length - 1
          ? "Ende"
          : `Nächste Übung: ${exercises[currentExercise + 1]}`
      );
      utterance.lang = 'de-DE';
      speechSynthesis.speak(utterance);
    }
  }, [currentExercise]);

  const playBeep = useCallback((frequency, duration) => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioCtx.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime);
    oscillator.connect(audioCtx.destination);
    oscillator.start();
    oscillator.stop(audioCtx.currentTime + duration);
  }, []);

  const playExerciseBeep = useCallback(() => playBeep(440, 0.1), [playBeep]);
  const playRestBeep = useCallback(() => playBeep(330, 0.2), [playBeep]);
  const playStartBeep = useCallback(() => playBeep(550, 0.2), [playBeep]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTimeLeft((time) => {
          if (time === 10) {
            if (isRest) {
              announceExercise();
            }
          }
          if (!isRest && time === Math.round(exerciseTime / 2)) {
            playRestBeep();
          }
          if (time <= 5 && time > 0) {
            if (isRest) {
              playRestBeep();
            } else {
              playExerciseBeep();
            }
          }
          if (time === 0) {
            if (isRest) {
              setIsRest(false);
              setTimeLeft(exerciseTime);
              if (currentExercise === exercises.length - 1) {
                if (round < 3) {
                  setRound(round + 1);
                  setCurrentExercise(0);
                  playStartBeep();
                } else {
                  setIsActive(false);
                  return 0;
                }
              } else {
                setCurrentExercise(prev => prev + 1);
                playStartBeep();
              }
            } else {
              setIsRest(true);
              setTimeLeft(restTime);
            }
            return time;
          }
          return time - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, isRest, currentExercise, round, playExerciseBeep, playRestBeep, announceExercise, playStartBeep]);

  const toggleTimer = () => {
    setIsActive(!isActive);
    if (!isActive && timeLeft === 0) {
      resetTimer();
    }
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsRest(true);
    setTimeLeft(restTime);
    setCurrentExercise(-1);
    setRound(1);
  };

  const addExercise = (newExercise) => {
    setExercises([...exercises, newExercise]);
  };

  const removeExercise = (index) => {
    setExercises(exercises.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-8">Bootcamp Workout Timer</h1>
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
        <div className="text-center mb-6">
          <p className="text-xl font-semibold mb-2">
            {isRest
              ? "Pause"
              : currentExercise === -1
                ? "Vorbereitung"
                : exercises[currentExercise]}
          </p>
          <p className="text-sm text-gray-600 mt-2">
            Danach: {isRest ? currentExercise === exercises.length - 1 ? exercises[0] : exercises[currentExercise + 1] : "Pause"}
          </p>
          <p className="text-6xl font-bold">{timeLeft}</p>
          <p className="text-sm text-gray-600 mt-2">
            Runde {round}/3 - Übung {currentExercise + 2}/9
          </p>
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={toggleTimer}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            {isActive ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button
            onClick={resetTimer}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            <RotateCcw size={24} />
          </button>
        </div>
      </div>
      <ExerciseManager
        exercises={exercises}
        setExercises={setExercises}
        addExercise={addExercise}
        removeExercise={removeExercise}
      />
    </div>
  );
};

export default BootcampTimer;

const ExerciseManager = ({ exercises, setExercises, addExercise, removeExercise }) => {
  const [newExercise, setNewExercise] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newExercise.trim()) {
      addExercise(newExercise.trim());
      setNewExercise('');
    }
  };

  const moveExercise = (index, direction) => {
    const newExercises = [...exercises];
    const [removed] = newExercises.splice(index, 1);
    newExercises.splice(index + direction, 0, removed);
    setExercises(newExercises);
  };

  return (
    <div className="mt-8 w-full max-w-md bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">Manage Exercises</h2>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="flex">
          <input
            type="text"
            value={newExercise}
            onChange={(e) => setNewExercise(e.target.value)}
            placeholder="New exercise"
            className="flex-grow p-2 border rounded-l"
          />
          <button type="submit" className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-r">
            Add
          </button>
        </div>
      </form>
      <ul className="space-y-2">
        {exercises.map((exercise, index) => (
          <li key={index} className="flex items-center bg-gray-100 p-2 rounded">
            <span className="flex-grow">{exercise}</span>
            <div className="flex space-x-2">
              <button
                onClick={() => moveExercise(index, -1)}
                disabled={index === 0}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded disabled:opacity-50"
              >
                ↑
              </button>
              <button
                onClick={() => moveExercise(index, 1)}
                disabled={index === exercises.length - 1}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded disabled:opacity-50"
              >
                ↓
              </button>
              <button
                onClick={() => removeExercise(index)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
